import React, { useState, useEffect, useRef } from "react";
import styles from "../Styles/Login.module.css";

import {
  FaCalendarAlt,
  FaClock,
  FaUser,
  FaArrowRight,
  FaPhone,
} from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axiosInstance from "../auth/axiosInstance";
import { Link, useNavigate, useLocation } from "react-router-dom"; // Import useLocation
import { DNA } from "react-loader-spinner";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Login = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [packages, setPackages] = useState([]);
  const [packageId, setPackageId] = useState();
  const [timeSlots, setTimeSlots] = useState([]);
  const [timeSlots1, setTimeSlots1] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [selectedAppointmentType, setSelectedAppointmentType] = useState("");
  const [fullName, setFullName] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [optionText, setOptionText] = useState("Select Date First");
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(false);
  const [isBangladesh, setIsBangladesh] = useState("bd");
  const navigate = useNavigate();
  const [duration, setDuration] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("bd"); // New state to track selected country
  const location = useLocation(); // Get location
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  useEffect(() => {
    console.log('eday dhokse')
    const { state } = location;
    if (state && state.selectedCountryCode) {
      console.log('eday o ki dhokse')
      setSelectedCountry(state.selectedCountryCode=='+880'?'bd':'other'); // Set the country from the OTP page
    }
  }, [location]);

  useEffect(() => {
    const verifyLogin = async () => {
      try {
        const response = await axiosInstance.post("/verify-login");
        const { isAuthenticated, phoneNumber } = response.data;
        setIsAuthenticated(isAuthenticated);
        setPhoneNumber(phoneNumber);
        if (response.data.isAuthenticated) {
          setLoading(false);
        } else {
          setTimeout(() => {
            navigate("/signup");
          }, 500);
        }
      } catch (error) {
        console.error("Error verifying login:", error);
        setError("Error verifying login. Please try again.");
        setTimeout(() => {
          navigate("/signup");
        }, 500);
      }
    };

    verifyLogin();
  }, [navigate]);

  const dateInputRef = useRef(null);
  const packageSelectRef = useRef(null);
  const timeSlotSelectRef = useRef(null);

  const formatDate = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const fetchPackages = async (date) => {
    const curDate = new Date();
    const selectedDate = new Date(date);
    curDate.setHours(0, 0, 0, 0);
    selectedDate.setHours(0, 0, 0, 0);
    console.log(curDate, selectedDate, 'dte dekhi koto ashe', selectedDate < curDate)

    if (selectedDate < curDate) {
      setTimeSlots([]);
      return;
    }

    try {
      const response = await axiosInstance.get(`/packages?formatedDate=${date}`);
      let timeSlotsData = response.data;

      if (selectedDate.toDateString() === curDate.toDateString()) {
        console.log('one')
        const currentTime = new Date().getHours() * 60 + new Date().getMinutes();

        const filteredSlots = timeSlotsData.filter(slot => {
          let [startHour, startMinute] = slot.time_slot.split(' ')[0].trim().split(':').map(Number);
          const det=slot.time_slot.split(' ')[1].trim();
          if (det.toString().toUpperCase() === 'PM' && startHour < 12) {
            startHour += 12;
          } else if (det.toString().toUpperCase() === 'AM' && startHour === 12) {
            startHour = 0; 
          }
          const startTime = startHour * 60 + startMinute;
          console.log(currentTime,slot,startTime,startHour, startMinute,det, "date dekhi kaj kore naki")
          return startTime > currentTime;
        });
        console.log(filteredSlots)

        setTimeSlots(filteredSlots);
        timeSlotsData=filteredSlots;
      } else {
        setTimeSlots(timeSlotsData);
      }

      const slotIds = timeSlotsData.map(slot => slot.slot_id);
      const data = [];

      if (slotIds.includes(1) && slotIds.includes(2)) {
        data.push({ slot_id: 12, time_slot: "9:00 AM - 10:30 AM" });
      }
      if (slotIds.includes(3) && slotIds.includes(4)) {
        data.push({ slot_id: 34, time_slot: "11:30 AM - 1:00 PM" });
      }
      if (slotIds.includes(5) && slotIds.includes(6)) {
        data.push({ slot_id: 56, time_slot: "2:00 PM - 3:30 PM" });
      }
      if (slotIds.includes(8) && slotIds.includes(9)) {
        data.push({ slot_id: 89, time_slot: "7:00 PM - 8:30 PM" });
      }

      setTimeSlots1(data);

      if (response.data.length === 0) {
        setOptionText("No Slots Available. Select Another Date.");
      }
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  };



  const fetchPackages1 = async () => {
    try {
      const response = await axiosInstance.get(`/packages1`);
      setPackages(response.data);
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  };

  const selectDate = (date) => {
    setSelectedDate(date);
    const formattedDate = formatDate(date);
    fetchPackages(formattedDate);
    setOptionText("Select One Slot");

    const dayOfWeek = date.getDay();
    if (dayOfWeek === 5) {
      setSelectedAppointmentType("Online");
    }
  };

  useEffect(() => {
    fetchPackages1();
  }, []);

  const handlePhoneNumberChange = (value, country) => {
    setPhoneNumber(value);
    setSelectedCountry(country.countryCode); 
  };

  const handleProceedToPayment = async (e) => {
    e.preventDefault();
    setError("");

    if (!isAuthenticated) {
      setError("User not authenticated. Please log in.");
    } else if (
      fullName === "" ||
      selectedTimeSlot === "" ||
      selectedAppointmentType === "" ||
      !selectedDate 
    ) {
      setError("Please fill out all fields.");
    } else if (!acceptedTerms) {
      setError("Please Agree to Terms and Conditions.");
    } else {
      setLoading1(true);
      const formData = {
        fullName,
        selectedTimeSlot,
        selectedAppointmentType,
        phoneNumber,
        selectedDate: formatDate(selectedDate),
        counsellingType: document.getElementById("sel").value,
        packageId
      };
      const encodedData = btoa(JSON.stringify(formData));
      console.log(formData);
      try {
        const response = await axiosInstance.post(
          `/payment`, { data: encodedData }
        );
        if (response.data.payment_url) {
          window.location.href = response.data.payment_url;
        } else {
          setLoading1(false)
          console.error("Payment URL not received.");
        }
      } catch (error) {
        console.error("Payment initiation failed:", error);
        setError("Please select all fields.");
        setLoading1(false)
      }
    }
  };

  const handleAppointmentTypeChange = (type) => {
    setSelectedAppointmentType(type);
  };

  const isAfterFiveThirty = (timeSlot) => {
    if (!timeSlot) return false;

    let endTimeInMinutes = 10;
    let fiveThirtyInMinutes = 20;
    if (timeSlot == "1" || timeSlot == "8" || timeSlot == "9") {
      endTimeInMinutes = 25;
    }
    return endTimeInMinutes > fiveThirtyInMinutes;
  };

  const isFriday = (date) => {
    const dayOfWeek = new Date(date).getDay();
    return dayOfWeek === 5;
  };
  const handleSelectChange = (event) => {
    
    const selectedValue = event.target.value.split(" ");
    
    //const selectedPackageId = parseInt(selectedValue[2], 10); //
    const selectedOption = event.target.options[event.target.selectedIndex]; // Get the selected <option> element
    const selectedPackageId = selectedOption.getAttribute("data-id");
    console.log('dhokse', selectedValue, selectedPackageId);
    setPackageId(selectedPackageId);

    const selectedPackage = packages.find((pkg) => pkg.package_id == selectedPackageId);
    console.log('dhokse1', selectedPackage);
    if (selectedPackage) {
      setDuration(selectedPackage.duration);

      if (selectedPackage.duration === "1.5") {

      }
    }
  };
  console.log("duration: ", packages);
  return (
    <div className={styles.login}>
      {!loading && (
        <>
          <br />
          <br />
          <h3>Book An Appointment</h3>
        </>
      )}
      {loading ? (
        <div className={styles.loader}>
          <DNA
            visible={true}
            height="170"
            width="170"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        </div>
      ) : (
        <form
          className={styles.appointmentForm}
          onSubmit={handleProceedToPayment}
        >
          <div className={styles.formGroup}>
            <label className={styles.labelName}>Your Full Name</label>
            <div className={styles.inputGroup}>
              <input
                type="text"
                placeholder="Write Your Full Name (As Per NID)"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
              <FaUser className={styles.customFa} />
            </div>
          </div>

          <div className={styles.formGroup}>
            <label className={styles.labelName}>Your Phone Number</label>
            <div className={styles.inputGroup}>
              <PhoneInput
                country={selectedCountry.countryCode}
                value={phoneNumber}
                countryCodeEditable={false}
                onChange={(value, country) => {
                  setPhoneNumber(value);
                  setSelectedCountry(country.countryCode);
                }}
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                  className: styles.phoneInput,
                }}
                containerClass={styles.customPhoneInput}
                buttonClass={styles.customPhoneInputButton}
                inputStyle={{
                  width: "100%",
                  height: "30px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                  paddingLeft: "48px",
                }}
                buttonStyle={{
                  borderRadius: "4px 0 0 4px",
                  border: "1px solid #ccc",
                }}
                dropdownStyle={{
                  display: 'none',
                  borderRadius: "0 0 4px 4px",
                  background: "#343434",
                  textAlign: "left",
                  color: "#666666",
                  zIndex: '999',
                }}
              />
              <FaPhone className={styles.icon} />
            </div>
          </div>

          <div className={styles.formGroup}>
            <label className={styles.labelName}>Counselling Type</label>
            <div className={styles.inputGroup}>
              <select
                ref={packageSelectRef}
                className={styles.customSelect}
                id="sel"
                onChange={handleSelectChange}
              >
                <option>Select One</option>
                {packages.map((pkg) => (
                  <option key={pkg.package_id} value={`${pkg.name} ${pkg.package_id} ${selectedCountry === "bd" ? `${pkg.price_inTaka} Taka` : `${pkg.price_inDollar} Dollar`}`} data-id={pkg.package_id}>
                    {pkg.name} - {selectedCountry === "bd" ? `${pkg.price_inTaka} Taka` : `$${pkg.price_inDollar}`}
                  </option>
                ))}
              </select>
              <img
                src={`${process.env.PUBLIC_URL}/arrow-down.svg`}
                className={styles.customFa}
                alt="arrow"
                onClick={() => packageSelectRef.current.focus()}
              />
            </div>
          </div>

          <div className={styles.formGroup}>
            <label className={styles.labelName}>Preferred Date</label>
            <div className={styles.inputGroup}>
              <FaCalendarAlt
                className={styles.customFa}
                onClick={() => dateInputRef.current.setOpen(true)}
              />
              <DatePicker
                ref={dateInputRef}
                selected={selectedDate}
                onChange={(date) => selectDate(date)}
                className={styles.customSelect}
                dateFormat="yyyy-MM-dd"
                placeholderText="yyyy-mm-dd"
                onClickOutside={() => dateInputRef.current.setOpen(false)}
              />
            </div>
          </div>

          <div className={styles.formGroup}>
            <label className={styles.labelName}>Preferred Time Slot</label>
            <div className={styles.inputGroup}>
              <select
                ref={timeSlotSelectRef}
                className={styles.customSelect}
                value={selectedTimeSlot}
                onChange={(e) => setSelectedTimeSlot(e.target.value)}
              >
                <option value="" disabled>
                  {optionText}
                </option>
                {duration == "1.0"
                  ? timeSlots.map((slot) => (
                    <option key={slot.slot_id} value={slot.slot_id}>
                      {slot.time_slot}
                    </option>
                  ))
                  : timeSlots1.map((slot) => (
                    <option key={slot.slot_id} value={slot.slot_id}>
                      {slot.time_slot}
                    </option>
                  ))}
              </select>
              <FaClock
                className={styles.customFa}
                onClick={() => timeSlotSelectRef.current.focus()}
              />
            </div>
          </div>

          <div className={`${styles.formGroup} ${styles.appointmentType}`}>
            <label className={styles.labelName}>Appointment Type</label>
            <div className={styles.buttonGroup}>
              <button
                type="button"
                className={`${styles.online} ${selectedAppointmentType === "Online" ? styles.selected : ""
                  }`}
                onClick={() => handleAppointmentTypeChange("Online")}
              >
                Online
              </button>
              {!isAfterFiveThirty(selectedTimeSlot) && !isFriday(selectedDate) && (
                <button
                  type="button"
                  className={`${styles.inPerson} ${selectedAppointmentType === "In Person" ? styles.selected : ""
                    }`}
                  onClick={() => handleAppointmentTypeChange("In Person")}
                >
                  In Person
                </button>
              )}
            </div>
          </div>
          <div className={styles.termsContainer}>
            <input
              type="checkbox"
              id="acceptTerms"
              checked={acceptedTerms}
              onChange={() => setAcceptedTerms(!acceptedTerms)}
              className={styles.checkbox}
            />
            <label htmlFor="acceptTerms" className={styles.lblterm} >
              I accept all the <Link to="/privacy" target="_blank" className={styles.tnc} >terms and conditions.</Link>
            </label>
          </div>

          {!isAuthenticated && (
            <div
              className={styles.error}
              style={{
                textAlign: "left",
                display: "flex",
                alignItems: "center",
              }}
            >
              <p>Please log in to proceed.</p>
              <Link to={"/signup"} style={{ color: "white", marginLeft: "5px" }}>
                click here
              </Link>
              .
            </div>
          )}

          {isAuthenticated && (
            <>
              {isAuthenticated && (
                <>
                  <button
                    type="submit"
                    className={styles.paymentButton}
                  //disabled={!acceptedTerms} // Disable if terms are not accepted
                  >
                   {loading1?'loading...':'Proceed To Payment'} <FaArrowRight className={styles.arrowIcon} />
                  </button>
                  {error && (
                    <div className={styles.error}>
                      <p style={{ color: "red" }}>{error}</p>
                    </div>
                  )}
                  <div className={styles.paymentOptions}>
                    <img
                      style={{ height: "20px", width: "150px" }}
                      src="bank.png"
                      alt="Visa Mastercard"
                    />
                    <span>+34 Other MFS And Banks</span>
                  </div>
                </>
              )}

            </>
          )}
        </form>
      )}
    </div>
  );
};

export default Login;
